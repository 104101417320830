.episodeIdColumn {
	width: 5%;
}

.episodeNameColumn {
	text-align: left;
	width: 25%;
}
.episodeDateColumn {
	width: 20%;
}
.episodeActionsColumn {
	width: 5%;
}
