.actionButtonFrequencies {
	width: auto;
	border: none;
	background-color: transparent;
	box-shadow: none;
	z-index: 0;
}

.freqInput {
	border: 1px solid transparent;
}

.freqVille,
.freqFMFreq,
.freqFM_picode,
.freqFM_bearer,
.freqDAB_eid,
.freqDAB_sid,
.freqDAB_bearer {
	width: 22%;
}

.freqAmazon {
	width: 3%;
	padding-left: 10px;
	text-overflow: clip;
}
.freqActions {
	min-width: 6%;
}
.freqActions:hover {
	background-color: #cbc7fa;
}

.freqLabel {
	font-weight: 600;
}

.hiddenTr {
	display: none;
}
