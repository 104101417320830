.sidebar {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	min-height: 100vh !important;
	z-index: 100;
	padding: 48px 0 0;
	box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
	background-color: #fcfbfb;
}

.sideBarLink {
	width: 98%;
	padding: 10px 5px 10px 5px;
	margin: 5px;
	color: #2c323f;
	text-decoration: none;
	font-size: 14px;
	font-family: Roboto, sans-serif;
	letter-spacing: 0.7px;
	text-transform: capitalize;
	font-weight: bold;
	white-space: nowrap;
}
.sideBarLink:hover {
	background-color: #eeecff;
	border-radius: 8px;
	color: #7366ff;
}
.sideBarLogo {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 90px;
	padding: 20px;
	color: #2c323f;
	background-color: #ffff;
	box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}
.menuDisplayChoice {
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30px;
	height: 30px;
	padding: 5px;
}
.menuDisplayChoice:hover {
	color: #7366ff;
}
.circle {
	position: absolute;
	border-radius: 100px;
	width: 18px;
	height: 18px;
	transition: transform 330ms ease-in-out;
}
.circle:hover {
	background: rgba(115, 102, 255, 0.2);
	transform: scale(2.2);
}

@media screen and (max-width: 768px) {
	.sideBarLink {
		width: 100%;
		padding: 10px;
		margin: 5px;
		color: #2c323f;
		text-decoration: none;
		font-size: 14px;
		font-family: Roboto, sans-serif;
		letter-spacing: 0.7px;
		text-transform: capitalize;
		font-weight: bold;
	}
}
