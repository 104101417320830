.listen,
.average,
.favorites,
.users,
.podcastsListensBloc {
	background-color: #7366ff;
	color: #fff;
	padding: 15px 0px 15px 15px;
	border-radius: 15px;
	width: 20%;
}
.seconds,
.podcastsAverageDurationBloc {
	background-color: #f73164;
	color: #fff;
	padding: 15px 0px 15px 15px;
	border-radius: 15px;
	width: 20%;
}
.users {
	margin-top: 20px;
}
.favorites,
.seconds,
.average,
.podcastsAverageDurationBloc {
	margin-left: 20px;
}
.textContentMetadata {
	z-index: 2;
	width: 60%;
}
.metadataUserIcon {
	z-index: 1;
	transition: all 0.3s ease;
}
.metadataUserIcon:hover {
	transform: rotate(-5deg) scale(1.1);
}
.formDateSubmitButton {
	margin-left: 1vh;
	margin-top: 4vh;
	background-color: #e5e5e5 !important;
	border-radius: 0.25rem;
}

@media only screen and (max-width: 991.98px) {
	.listen,
	.average,
	.favorites,
	.users,
	.seconds,
	.podcastsAverageDurationBloc,
	.podcastsListensBloc {
		width: 100%;
		padding: 5px 0px 5px 5px;
	}
	.textContentMetadata {
		width: 70%;
	}
	.listen,
	.average,
	.favorites,
	.users,
	.seconds,
	.podcastsAverageDurationBloc {
		margin-top: 20px;
		margin-left: 0px;
	}
	.formDateSubmitButton {
		margin-left: 0vh;
		margin-top: 2vh;
	}
}
