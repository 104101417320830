.usersList {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}

.sorteWrapper {
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin-bottom: 2vh;
}
#alert {
	display: none;
}
@media (max-width: 768px) and (orientation: portrait) {
	#table {
		display: none;
	}
	#alert {
		display: block;
		margin-top: 50px;
		font-size: 24px;
	}
}
@media only screen and (orientation: landscape) {
	#alert {
		display: none;
	}
}
