.App {
	text-align: center;
	min-height: 100vh;
	background-color: #f8f8f8;
}
textarea,
input:not(
		.formSubmitButton,
		.radioButton,
		.paramsFormButton,
		.weekDay,
		.updateUserFormButton,
		.formDateSubmitButton,
		.formInputFrequencies
	) {
	background: #fff !important;
	border: 1px solid #ced4da !important;
}
input:focus,
textarea:focus {
	box-shadow: 0px 0px 3px 3px #c2dbfe !important;
}
a {
	text-decoration: none;
}

.scrollNone::-webkit-scrollbar {
	display: none;
}
