.dataInfoBloc {
	font-size: 24px;
}

@media only screen and (max-width: 1200px) {
	.dataInfoBloc {
		font-size: 16px;
	}
}

@media only screen and (max-width: 992px) {
	.dataInfoBloc {
		font-size: 20px;
	}
}
