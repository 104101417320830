.RadioManager {
	font-size: 14px;
}
.formSubmitButton {
	background-color: #7366ff;
	color: #fff;
	padding: 0.375rem 1.75rem;
	border-radius: 0.25rem;
	border-color: #7366ff;
}
.streamDeleteButton {
	border: none;
	background-color: #ffff;
	cursor: pointer;
}

select {
	background: none;
}
