#goTop {
	display: block;
	width: 20px;
	text-align: center;
	cursor: pointer;
	width: 40px;
	height: 40px;
	border-radius: 100%;
	position: fixed;
	bottom: 30px;
	right: 30px;
	z-index: 5;
	color: #fff;
	background: #7666ff;
	border: none;
	padding: 7px;
	box-shadow: 0 0 10px var(--theme-deafult);
	opacity: 0.5;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
	width: 24;
	height: 24;
	fill: none;
	stroke-width: 2;
}

#goTop:hover {
	background: #1800f5;
}
