.lineChart {
	width: 100%;
	height: 300px;
	border-radius: 5px;
}

.customTooltip {
	width: 30vh;
	border: 2px solid #eceff1;
	border-radius: 10px !important;
	background-color: #fefcfc;
	box-shadow: 5px 2px 2px rgba(133, 131, 131, 0.5);
}
.toolTipTitle {
	background-color: #eceff1;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding-left: 2vh;
	font-size: 13px;
	height: 3vh;
}
.toolTipContent {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: column;
	padding: 2vh 0 0 2vh;
	font-size: 12px;
}
.dot {
	width: 1.5vh;
	height: 1.5vh;
	border-radius: 50px;
}
@media screen and (max-width: 768px) {
	.customTooltip {
		width: 25vh;
	}
}
