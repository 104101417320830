.mediasCounterWrapper {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	font-size: 16px;
	width: 50%;
}
.mediaCounter {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin: 5px 0 5px 0;
}
.mediaCounterLabel {
	text-align: start;
	width: 100%;
	background-color: #f8f8f8;
	border-radius: 5px 0 0 5px;
	padding: 3px;
}
.mediaCounterValue {
	background-color: #e9ecef;
	width: 15%;
	height: 30px;
	border-radius: 0 5px 5px 0;
	text-align: end;
	padding: 3px;
}

@media only screen and (max-width: 1500px) {
	.mediasCounterWrapper {
		width: 70%;
	}
	.mediaCounterValue {
		width: 16%;
	}
}

@media only screen and (max-width: 1150px) {
	.mediasCounterWrapper {
		width: 90%;
	}
}

@media only screen and (max-width: 1000px) {
	.mediasCounterWrapper {
		width: 100%;
	}

	.mediaCounterValue {
		width: 20%;
	}
}
@media only screen and (max-width: 950px) {
	.mediaCounterValue {
		min-width: 120px;
	}
}
