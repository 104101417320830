.cancelButton,
.sendButton,
.loadJson,
.gobackButton {
	width: 120px;
	background-color: #fff;
	border: 1px solid #d5d9d9;
	border-radius: 8px;
	box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
	box-sizing: border-box;
	color: #0f1111;
	cursor: pointer;
	display: inline-block;
	font-size: 13px;
	line-height: 29px;
	padding: 0 10px 0 11px;
	position: relative;
	text-align: center;
	text-decoration: none;
	user-select: none;
	-webkit-user-select: none;
	touch-action: manipulation;
	vertical-align: middle;
}

.cancelButton:hover {
	background-color: #e2342e;
	color: #fff;
}

.cancelButton:focus {
	border-color: #008296;
	box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
	outline: 0;
}
.sendButton:hover {
	background-color: #36ad68;
	color: #fff;
}

.sendButton:focus {
	border-color: #008296;
	box-shadow: rgba(39, 129, 129, 0.5) 0 2px 5px 0;
	outline: 0;
}

.loadJson:hover,
.gobackButton:hover {
	background-color: #7366ff;
	color: #fff;
}

.loadJson:focus,
.gobackButton:focus {
	border-color: #008296;
	box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
	outline: 0;
}
