.pieChart {
	width: 100%;
}
.customTooltipPie {
	width: 18vh;
	height: 3.5vh;
	border-radius: 5px !important;
	box-shadow: 2px 2px 2px rgba(207, 205, 205, 0.5);
	color: #fff;
	font-size: 1.5vh;
}
.custom-pie:hover {
	opacity: 0.5 !important;
}
@media screen and (max-width: 768px) {
	.pieChart {
		width: 50%;
	}
	.customTooltipPie {
		width: 20vh;
	}
}
@media screen and (max-width: 1439px) {
	.pieChart {
		width: 80%;
	}
}
