.labelName {
	display: flex;
	justify-content: flex-start;
}

.updateUserFormButton {
	background-color: #8c82f8;
	color: #fff;
	border-radius: 5px;
	padding: 5px;
	border: none;
}

.updateUserFormButton:hover {
	background-color: #5f51f0;
	color: #fff;
}
.modaleCloseButton {
	cursor: pointer;
}
