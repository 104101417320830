.deleteUserFormButton {
	width: 9vh;
	margin: 10px;
	background-color: #f75151;
	color: #fff;
	border-radius: 5px;
	padding: 5px;
	border: none;
}

.deleteUserFormButton:hover {
	background-color: #f21313;
	color: #fff;
}
