.loginPage {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-image: url('../../assets/login_bg.jpg');
	background-size: contain;
}
.loginCard {
	max-width: 55vh;
}
.loginButton {
	background-color: #7366ff;
	color: #fff;
	font-size: 14px;
}

.signupButton {
	background-color: #f07320;
	color: #fff;
	font-size: 14px;
}
.changeForm {
	cursor: pointer;
}
.card-text {
	font-size: 14px;
	color: #898989;
}

.form-control {
	background-color: #e8f0fe;
	border: none;
	transition: none;
}
.form-control:focus {
	background-color: #e8f0fe;
	border: 1px solid #ced4da;
	box-shadow: none;
}
.eyeIcon {
	border-radius: 0 0.375rem 0.375rem 0;
	cursor: pointer;
	border: 1px solid #ced4da;
}
.changingPassword {
	cursor: pointer;
}
