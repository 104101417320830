.iaFormWidth {
	width: 50%;
}
@media only screen and (max-width: 992px) {
	.iaFormWidth {
		width: 75%;
	}
}

@media only screen and (max-width: 768px) {
	.iaFormWidth {
		width: 90%;
	}
}
