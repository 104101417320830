.allowUserFormButton {
	width: 9vh;
	margin: 10px;
	background-color: #4eb455;
	color: #fff;
	border-radius: 5px;
	padding: 5px;
	border: none;
}

.allowUserFormButton:hover {
	background-color: #039c0d;
	color: #fff;
}

.noAllowUserFormButton {
	width: 9vh;
	margin: 10px;
	background-color: #e09545;
	color: #fff;
	border-radius: 5px;
	padding: 5px;
	border: none;
}

.noAllowUserFormButton:hover {
	background-color: #e57e10;
	color: #fff;
}
