.rp-triangle {
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 100%;
	align-items: center;
	margin: 0;
	background-color: #fff;
	height: 60vh;
	border-radius: 10px;
}

.triangle {
	position: relative;
	width: 120px;
	height: 120px;
	display: inline-block;
	z-index: 99;
	background-color: #fff;
	align-self: center;
}
.bg-triangle {
	position: relative;
	width: 0;
	height: 0;
	border-top: 61px solid #fff;
	border-bottom: 61px solid #fff;
	border-left: 120px solid transparent;
	z-index: 99;
}

.band1 {
	display: inline-block;
	position: relative;
	top: -100%;
	height: 100%;
	width: 15%;
	margin-left: 1%;
	background-color: #df9898;
	animation: animateBand 1.5s -0.8s ease-in-out infinite;
	z-index: 1;
}

.band2 {
	display: inline-block;
	position: relative;
	top: -100%;
	height: 100%;
	width: 15%;
	margin-left: 3%;
	background-color: #df9898;
	animation: animateBand 1.5s -0.6s ease-in-out infinite;
	z-index: 1;
}

.band3 {
	display: inline-block;
	position: relative;
	top: -100%;
	height: 100%;
	width: 15%;
	margin-left: 3%;
	background-color: #df9898;
	animation: animateBand 1.5s -0.4s ease-in-out infinite;
	z-index: 1;
}

.band4 {
	display: inline-block;
	position: relative;
	top: -100%;
	height: 100%;
	width: 15%;
	margin-left: 3%;
	background-color: #df9898;
	animation: animateBand 1.5s -0.2s ease-in-out infinite;
	z-index: 1;
}

.band5 {
	display: inline-block;
	position: relative;
	top: -100%;
	height: 100%;
	width: 23%;
	margin-left: 3%;
	background-color: #df9898;
	animation: animateBand 1.5s ease-in-out infinite;
	z-index: 1;
}

@keyframes animateBand {
	0%,
	100% {
		background-color: #df9898;
	}
	50% {
		background-color: #d70404;
	}
}
