.insideMenuLink {
	text-decoration: none;
	font-size: 13px;
	color: #7366ff;
	font-family: Roboto, sans-serif;
}
.insideMenuTitle {
	color: #2b2b2b;
	font-weight: bold;
}
.logoHouseMenu {
	text-decoration: none;
}
