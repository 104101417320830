.actionButton {
	background-color: #f8f8f8;
	border: 1px solid #d5d9d9;
	border-radius: 8px;
	box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
	box-sizing: border-box;
	color: #0f1111;
	cursor: pointer;
	display: inline-block;
	font-size: 13px;
	line-height: 19px;
	padding: 0 10px 0 11px;
	position: relative;
	text-align: center;
	text-decoration: none;
	user-select: none;
	-webkit-user-select: none;
	touch-action: manipulation;
	vertical-align: middle;
	width: 100px;
}

.actionButton:hover {
	background-color: #cbc7fa;
}

.actionButton:focus {
	border-color: #008296;
	box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
	outline: 0;
}
.radioIdColumn {
	width: 5%;
}
.radioLogoColumn,
.localsIdColumn {
	width: 5%;
}
.radioNameColumn,
.articlesTitle {
	width: 20%;
}

.radioTypeColumn {
	width: 20%;
}

.radioSloganColumn,
.localsNameColumn,
.localsGeofootprintColumn {
	width: 30%;
}
.radioActionsColumn,
.onboardingActionsColumn,
.localsActionsColumn {
	width: 5%;
}
tr:hover {
	background-color: #f6f6f6;
}

.pendingIdNameColumn {
	width: 7%;
}

.onboardingIdColumn {
	width: 7%;
}
.onboardingCoverColumn {
	width: 20%;
}
.onboardingTitleColumn {
	text-align: start;
	width: 20%;
}
.onboardingValidDateColumn {
	width: 20%;
}
.articlesId {
	width: 5%;
}
.articleColumnImage,
.articleColumnState,
.articleColumnDate {
	max-width: 8%;
}
.articleColumnTitle,
.articleColumnContent {
	width: 33%;
}
.articleColumnId,
.articleColumnActions {
	width: 5%;
}

table {
	width: 100%;
}
