.otherPodcastsStatus {
	color: #7366ff;
	letter-spacing: 1px;
	cursor: pointer;
}

.formSubmitButton {
	background-color: #7366ff !important;
	color: #fff !important;
	padding: 0.375rem 1.75rem;
	border-radius: 0.25rem;
	border-color: #7366ff;
}
