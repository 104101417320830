.scrollPane {
	padding: 10px;
	display: flex;
	scroll-snap-type: x mandatory;
	overflow-x: scroll;
	width: 100%;
}

.logoCard {
	display: flex;
	flex-direction: column;
	width: 100px;
	margin-right: 10px;
	flex: 0 0 auto;
	scroll-snap-align: center;
	border: none;
}
