.footer {
	position: absolute;
	left: 0;
	bottom: 0;
	font-size: 1.2em;
	width: 100%;
}
.footer-copyright {
	margin: auto;
}

.footer-badge-ios {
	height: 40px;
	width: auto;
	margin: 0.3em;
}
.footer-badge-android {
	height: 50px;
	width: auto;
	margin: 0.3em;
}
.store-logo {
	font-size: 0.8em;
	padding: 0px 2px 0px 3px;
	vertical-align: 4px;
}
